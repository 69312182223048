<template>
  <v-container>
    <div class="text-right">
      <v-btn
        v-if="isAuthorized('note', 'create')"
        color="info"
        class="mb-2 mr-2"
        @click="
          editMode = false;
          formDialog = true;
        "
        >Lisää muistiinpano</v-btn
      >
    </div>

    <v-row dense>
      <!-- Loader -->
      <full-page-loader
        v-if="loading"
        text="Ladataan muistiinpanoja..."
        class="full-page-loader"
      ></full-page-loader>

      <!-- NOTE LIST -->
      <v-col v-else class="notes-wrapper" cols="12" md="7">
        <note-list @deletenote="deleteOne" @openforedit="openForEdit"></note-list>
      </v-col>
    </v-row>

    <!-- Form dialog -->
    <note-form-dialog
      v-model="formDialog"
      :edit="editMode"
      @createnote="createOne"
      @editnote="editOne"
      :model="model"
    ></note-form-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";
import NoteList from "./NoteList.vue";
import NoteFormDialog from "./NoteFormDialog.vue";
import FullPageLoader from "@/components/FullPageLoader.vue";

export default {
  mixins: [mixins],
  props: ["model"],

  data() {
    return {
      editMode: false,
      formDialog: false,
    };
  },

  components: {
    NoteFormDialog,
    NoteList,
    FullPageLoader,
  },

  async created() {
    try {
      this.setLoading(true);
      await this.getNotes({ model: this.model, documentId: this.$route.params.id });
      this.setLoading(false);
    } catch (err) {
      this.showPopup(err);
    }
  },

  computed: {
    ...mapState("note", ["currentNote", "loading"]),
  },

  methods: {
    ...mapActions("note", ["getNotes", "createNote", "deleteNote", "editNote"]),
    ...mapMutations("note", ["setCurrentNote", "setLoading"]),

    async createOne() {
      try {
        await this.createNote({
          note: this.currentNote,
          model: this.model,
          documentId: this.$route.params.id,
        });
        this.formDialog = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async editOne() {
      try {
        await this.editNote({
          note: this.currentNote,
          model: this.model,
          documentId: this.$route.params.id,
        });
        this.formDialog = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteOne(id) {
      try {
        await this.deleteNote({ noteId: id, model: this.model, documentId: this.$route.params.id });
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    openForEdit(note) {
      this.setCurrentNote(note);
      this.editMode = true;
      this.formDialog = true;
    },
  },
};
</script>

<style scoped>
.notes-wrapper {
  max-height: 600px;
  overflow-y: auto;
}
</style>
